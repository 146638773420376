<template>
  <div class="test-devices q-pa-md">
    <template v-if="isError">
      <div>Failed to fetch list of test devices</div>
    </template>
    <div v-else class="column">
      <test-device-header
        :add-new-device-disabled="isLoading"
        @addNewDeviceClick="openAddDialog"
      />
      <test-device-cards
        v-if="hasTestDevices"
        :test-devices="testDevices"
        @removeDevice="openRemoveDialog($event)"
      />
      <span v-else>
        You have no test devices. Register one using "Add new test device"
        button
      </span>
    </div>
    <q-inner-loading :showing="isLoading">
      <q-spinner size="50px" color="primary" />
    </q-inner-loading>
  </div>
</template>
<script lang="ts">
import { computed, defineComponent } from "vue";

import TestDeviceCards from "@/components/testdevices/TestDeviceCards.vue";
import TestDeviceHeader from "@/components/testdevices/TestDeviceHeader.vue";
import { useTestDevicesAddDialog } from "@/composables/testdevices/useTestDevicesAddDialog";
import { useTestDevicesList } from "@/composables/testdevices/useTestDevicesList";
import { useTestDevicesRemoveDialog } from "@/composables/testdevices/useTestDevicesRemoveDialog";
import { DataState } from "@/composables/typedefs";

export default defineComponent({
  components: {
    TestDeviceCards,
    TestDeviceHeader,
  },
  setup() {
    const { testDevices, tryToDeleteTestDevice, tryToFetchTestDevices, state } =
      useTestDevicesList();

    const { openDialog: openAddDialog } = useTestDevicesAddDialog({
      onDismiss: () => tryToFetchTestDevices(),
    });

    const { openDialog: openRemoveDialog } = useTestDevicesRemoveDialog({
      onOk: (deviceId) => tryToDeleteTestDevice(deviceId),
    });

    const hasTestDevices = computed(() => testDevices.value.length > 0);

    return {
      tryToDeleteTestDevice,
      openAddDialog,
      openRemoveDialog,
      testDevices,
      hasTestDevices,
      isLoading: computed(() => state.value === DataState.LOADING),
      isError: computed(() => state.value === DataState.ERROR),
    };
  },
});
</script>
<style lang="scss" scoped>
.test-devices {
  width: 100%;
}
</style>
