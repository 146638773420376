import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "nav-menu-expandable" }

import { PropType, watch } from "vue";
import { computed, ComputedRef, ref, Ref } from "vue";

import { NavMenuItem } from "@/composables/typedefs";

const NO_SELECTION_ICON = "more_horiz";
const NO_NAME_SYMBOL = "--";


export default /*@__PURE__*/_defineComponent({
  __name: 'NavMenuExpandable',
  props: {
  items: {
    type: Array as PropType<NavMenuItem[]>,
    required: true,
  },
  caption: {
    type: String,
    required: true,
  },
  selectedItem: {
    type: Object as PropType<NavMenuItem | null>,
    default: null,
  },
  // controls whether expandable is forced to be always expanded. If so, clicks on
  // items or header do not collapse/open the expandable
  alwaysExpanded: {
    type: Boolean,
    default: false,
  },
},
  emits: ["choose"],
  setup(__props, { emit: __emit }) {

const props = __props;

const emits = __emit;

const icon: ComputedRef<string> = computed(() => {
  const selectedIcon: string = props.selectedItem?.icon ?? "";
  if (selectedIcon) {
    return `img:${selectedIcon}`;
  }
  return NO_SELECTION_ICON;
});

// toggle tracks the state of whether expandable module should be expanded (toggle=true)
// as if alwaysExpanded property didn't matter
const toggle: Ref<boolean> = ref(props.alwaysExpanded || !props.selectedItem);

// expanded is the actual expanded state of the expandable menu module that takes
// into account both the toggle state and alwaysExpanded property
const expanded = computed<boolean>(() => {
  if (props.alwaysExpanded) {
    return true;
  }
  return toggle.value;
});

// Note: this watch is needed to reset the initial toggle state every time
// the alwaysExpanded property is modified by parent component. Without it
// there could be situation (e.g. after page refresh) where expandable does
// not collapse as intended.
watch(
  () => props.alwaysExpanded,
  (alwaysExpanded: boolean) => {
    if (!alwaysExpanded) {
      toggle.value = props.alwaysExpanded || !props.selectedItem;
    }
  }
);
const itemClicked = (item: NavMenuItem) => {
  emits("choose", item);
  if (!props.alwaysExpanded) {
    toggle.value = false;
  }
};

const isDisabled: ComputedRef<boolean> = computed(() => !props.items.length);
const label: ComputedRef<string> = computed(
  () => props.selectedItem?.name || NO_NAME_SYMBOL
);
const isSelected = (name: string): boolean => props.selectedItem?.name === name;

return (_ctx: any,_cache: any) => {
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_img = _resolveComponent("q-img")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_expansion_item = _resolveComponent("q-expansion-item")!
  const _directive_ripple = _resolveDirective("ripple")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (isDisabled.value)
      ? (_openBlock(), _createBlock(_component_q_tooltip, {
          key: 0,
          anchor: "top middle"
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode(" No items to choose from ")
          ])),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_q_expansion_item, {
      "model-value": expanded.value,
      "expand-separator": "",
      "data-test": "navmenuexpandable",
      label: label.value,
      caption: __props.caption,
      icon: icon.value,
      disable: isDisabled.value
    }, {
      default: _withCtx(() => [
        _createVNode(_component_q_list, null, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.items, (item) => {
              return _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                key: item.name,
                ref_for: true,
                ref: item.name,
                clickable: "",
                "data-test": "navmenu.menu-item",
                class: _normalizeClass(["item", {
            selected: isSelected(item.name),
            unselected: !isSelected(item.name),
          }]),
                onClick: ($event: any) => (itemClicked(item))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_item_section, { avatar: "" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_img, {
                        src: item.icon,
                        height: "25px",
                        fit: "scale-down"
                      }, null, 8, ["src"])
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_q_item_section, { "data-test": "navmenu.item" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.name), 1)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1032, ["class", "onClick"])), [
                [_directive_ripple]
              ])
            }), 128))
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model-value", "label", "caption", "icon", "disable"])
  ]))
}
}

})