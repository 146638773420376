<template>
  <div class="field">
    <span>{{ label }}</span>
    {{ value }}
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.field {
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  span {
    font-size: 0.8rem;
  }
}
</style>
