import {
  RouteLocationNormalized,
  RouteLocationRaw,
  RouteRecordRaw,
} from "vue-router";

import { castString } from "@/lib/casts";
import { castToProductId, castToProductLineId } from "@/lib/url";

import { mapParamsRouteGuard } from "../mapParamsRouteGuard";

export const routesPushMessages: RouteRecordRaw[] = [
  {
    name: "PushMessage",
    path: "/pushnotifications/message/:product(\\w+)/:productLine(\\w+)/:pushMessageId(\\d+)?/",
    component: () => import("@/views/pushmessages/PushMessage.vue"),
    beforeEnter: mapParamsRouteGuard,
    props: (route) => {
      return {
        pushMessageId: route.params.pushMessageId,
      };
    },
  },
  {
    path: "/pushnotifications/message-list/:product(\\w+)/:productLine(\\w+)/",
    name: "PushMessagesListAlias",
    redirect: (to: RouteLocationNormalized): RouteLocationRaw => {
      const product = to.params.product;
      const productLine = to.params.productLine;

      let productId;
      let productLineId;
      try {
        productId = castToProductId(castString(product));
        productLineId = castToProductLineId(
          castString(productLine),
          String(productId)
        );
      } catch {
        return { name: "NotFound" };
      }

      return {
        name: "PushMessagesList",
        params: {
          product: productId,
          productLine: productLineId,
        },
      };
    },
  },
  {
    name: "PushMessagesList",
    path: "/pushnotifications/message-list/:product(\\d+)/:productLine(\\d+)/",
    component: () => import("@/views/pushmessages/PushMessages.vue"),
    props: (route) => {
      return {
        queryParams: route.query,
        product: Number(route.params.product),
        productLine: Number(route.params.productLine),
      };
    },
  },
  {
    name: "PushMessageShortcut",
    path: "/pushnotifications/message/:pushMessageId(\\d+)?/",
    component: () => import("@/views/pushmessages/PushMessageShortcut.vue"),
    props: (route) => {
      return {
        pushMessageId: route.params.pushMessageId,
      };
    },
  },
];
