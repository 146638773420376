<template>
  <div class="col header">
    <div class="text-h2 q-my-md">Test devices</div>
    <div>
      <q-btn
        icon="add"
        class="q-ml-md"
        color="primary"
        :disable="addNewDeviceDisabled"
        data-test="testdevice.header.button.add"
        @click="$emit('addNewDeviceClick')"
        >Add new test device</q-btn
      >
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    addNewDeviceDisabled: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["addNewDeviceClick"],
});
</script>
<style lang="scss" scoped>
.header {
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>
