<template>
  <div class="nav-menu-expandable">
    <q-tooltip v-if="isDisabled" anchor="top middle">
      No items to choose from
    </q-tooltip>
    <q-expansion-item
      :model-value="expanded"
      expand-separator
      data-test="navmenuexpandable"
      :label="label"
      :caption="caption"
      :icon="icon"
      :disable="isDisabled"
    >
      <q-list>
        <q-item
          v-for="item in items"
          :key="item.name"
          :ref="item.name"
          v-ripple
          clickable
          data-test="navmenu.menu-item"
          class="item"
          :class="{
            selected: isSelected(item.name),
            unselected: !isSelected(item.name),
          }"
          @click="itemClicked(item)"
        >
          <q-item-section avatar>
            <q-img :src="item.icon" height="25px" fit="scale-down" />
          </q-item-section>
          <q-item-section data-test="navmenu.item">
            {{ item.name }}
          </q-item-section>
        </q-item>
      </q-list>
    </q-expansion-item>
  </div>
</template>

<script lang="ts" setup>
import { PropType, watch } from "vue";
import { computed, ComputedRef, ref, Ref } from "vue";

import { NavMenuItem } from "@/composables/typedefs";

const NO_SELECTION_ICON = "more_horiz";
const NO_NAME_SYMBOL = "--";

const props = defineProps({
  items: {
    type: Array as PropType<NavMenuItem[]>,
    required: true,
  },
  caption: {
    type: String,
    required: true,
  },
  selectedItem: {
    type: Object as PropType<NavMenuItem | null>,
    default: null,
  },
  // controls whether expandable is forced to be always expanded. If so, clicks on
  // items or header do not collapse/open the expandable
  alwaysExpanded: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits(["choose"]);

const icon: ComputedRef<string> = computed(() => {
  const selectedIcon: string = props.selectedItem?.icon ?? "";
  if (selectedIcon) {
    return `img:${selectedIcon}`;
  }
  return NO_SELECTION_ICON;
});

// toggle tracks the state of whether expandable module should be expanded (toggle=true)
// as if alwaysExpanded property didn't matter
const toggle: Ref<boolean> = ref(props.alwaysExpanded || !props.selectedItem);

// expanded is the actual expanded state of the expandable menu module that takes
// into account both the toggle state and alwaysExpanded property
const expanded = computed<boolean>(() => {
  if (props.alwaysExpanded) {
    return true;
  }
  return toggle.value;
});

// Note: this watch is needed to reset the initial toggle state every time
// the alwaysExpanded property is modified by parent component. Without it
// there could be situation (e.g. after page refresh) where expandable does
// not collapse as intended.
watch(
  () => props.alwaysExpanded,
  (alwaysExpanded: boolean) => {
    if (!alwaysExpanded) {
      toggle.value = props.alwaysExpanded || !props.selectedItem;
    }
  }
);
const itemClicked = (item: NavMenuItem) => {
  emits("choose", item);
  if (!props.alwaysExpanded) {
    toggle.value = false;
  }
};

const isDisabled: ComputedRef<boolean> = computed(() => !props.items.length);
const label: ComputedRef<string> = computed(
  () => props.selectedItem?.name || NO_NAME_SYMBOL
);
const isSelected = (name: string): boolean => props.selectedItem?.name === name;
</script>

<style scoped>
.item {
  /* adding some negative space to make individual items stand out */
  padding-left: 2rem;
}
.unselected {
  opacity: 0.3;
}
</style>
