<template>
  <q-card class="device-card" data-test="testdevice.card">
    <q-card-section>
      <q-icon size="md" name="phone_iphone" />
    </q-card-section>
    <q-card-section>
      <test-device-card-field
        data-test="testdevice.card.field.devicename"
        label="Device name"
        :value="testDevice.deviceName"
      ></test-device-card-field>
      <test-device-card-field
        data-test="testdevice.card.field.product"
        label="Product"
        :value="productLabel"
      ></test-device-card-field>
      <test-device-card-field
        data-test="testdevice.card.field.leanplumuserid"
        label="Leanplum User ID"
        :value="testDevice.leanplumUserId"
      ></test-device-card-field>
    </q-card-section>
    <q-separator />
    <q-card-actions>
      <q-btn
        data-test="testdevice.card.button.remove"
        flat
        text-color="negative"
        @click="$emit('removeDevice')"
        >Remove device</q-btn
      >
    </q-card-actions>
  </q-card>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import { TestDevice } from "@/api/testdevices";

import TestDeviceCardField from "./TestDeviceCardField.vue";

export default defineComponent({
  components: {
    TestDeviceCardField,
  },
  props: {
    testDevice: {
      type: Object as PropType<TestDevice>,
      required: true,
    },
  },
  emits: ["removeDevice"],
  computed: {
    productLabel(): string {
      return `${this.testDevice.product} / ${this.testDevice.productLine}`;
    },
  },
});
</script>
