<template>
  <q-dialog
    ref="dialogRef"
    data-test="testdevice.adddialog"
    @hide="onDialogHide"
  >
    <q-card class="dialog-card q-dialog-plugin">
      <q-card-section v-if="isError">
        <h3>Failed to fetch registration token</h3>
      </q-card-section>
      <template v-else>
        <q-card-section>
          <div class="text-h4 q-my-md">Register new test device</div>
          <p>
            Scan the QR code or enter the link below on the device you want to
            register.
          </p>
        </q-card-section>
        <q-card-section class="qrcode">
          <canvas ref="qrCodeCanvas"></canvas>
        </q-card-section>
        <q-card-section data-test="testdevice.adddialog.url">
          <a :href="url">
            <code>{{ url }}</code>
          </a>
        </q-card-section>
      </template>
      <q-card-actions align="right">
        <q-btn label="Close" @click="onDialogOK" />
      </q-card-actions>
      <q-inner-loading :showing="isLoading">
        <q-spinner size="50px" color="primary" />
      </q-inner-loading>
    </q-card>
  </q-dialog>
</template>

<script lang="ts">
import { defineComponent } from "@vue/runtime-core";
import QRCode from "qrcode";
import { useDialogPluginComponent } from "quasar";
import { computed, Ref, ref, watch } from "vue";

import { useTestDevicesToken } from "@/composables/testdevices/useTestDevicesToken";
import { DataState } from "@/composables/typedefs";

export default defineComponent({
  emits: Array.from(useDialogPluginComponent.emits),
  setup() {
    const { dialogRef, onDialogOK, onDialogHide } = useDialogPluginComponent();
    const { url, state } = useTestDevicesToken();
    const qrCodeCanvas: Ref<HTMLCanvasElement | null> = ref(null);

    watch([url], () => {
      const canvasEl = qrCodeCanvas.value;
      const qrCodeUrl = url.value;
      if (canvasEl === null || qrCodeUrl === "") {
        return;
      }

      QRCode.toCanvas(canvasEl, qrCodeUrl);
    });

    return {
      url,
      qrCodeCanvas,
      isLoading: computed(() => state.value === DataState.LOADING),
      isError: computed(() => state.value === DataState.ERROR),
      onDialogHide,
      dialogRef,
      onDialogOK,
    };
  },
});
</script>
<style lang="scss" scoped>
.dialog-card {
  min-width: min(600px, 100vw);
}
.qrcode {
  display: flex;
  justify-content: center;
  img {
    height: 300px;
  }
}
</style>
