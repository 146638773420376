<template>
  <div class="error text-negative">
    <q-icon :name="icon" size="xl" />
    <div>{{ message }}</div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    message: {
      type: String,
      required: false,
      default: "An error has occurred",
    },
    icon: {
      type: String,
      required: false,
      default: "error_outline",
    },
  },
});
</script>

<style lang="scss" scoped>
.error {
  margin: auto;
  text-align: center;

  div {
    margin-top: 1rem;
  }
}
</style>
