<template>
  <q-dialog
    ref="dialogRef"
    data-test="testdevice.removedialog"
    @hide="onDialogHide"
    @keyup.enter="onDialogOK"
  >
    <q-card class="q-dialog-plugin">
      <q-card-section>
        <div class="text-h4 q-my-md">Test device removal</div>
        <p>Are you sure you want to remove this device?</p>
      </q-card-section>
      <q-card-actions align="right">
        <q-btn
          color="negative"
          label="Remove"
          tabindex="0"
          data-test="testdevice.removedialog.button.remove"
          @click="onDialogOK"
        />
        <q-btn
          color="dark"
          label="Cancel"
          tabindex="1"
          @click="onDialogCancel"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script lang="ts">
import { useDialogPluginComponent } from "quasar";

export default {
  emits: Array.from(useDialogPluginComponent.emits),

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup() {
    const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } =
      useDialogPluginComponent();

    return {
      dialogRef,
      onDialogHide,
      onDialogOK,
      onDialogCancel,
    };
  },
};
</script>
