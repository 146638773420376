<template>
  <div class="col row items-start q-gutter-md">
    <test-device-card
      v-for="testDevice in testDevices"
      :key="testDevice.id"
      :test-device="testDevice"
      @removeDevice="$emit('removeDevice', testDevice.id)"
    />
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";

import { TestDevice } from "@/api/testdevices";

import TestDeviceCard from "./TestDeviceCard.vue";

export default defineComponent({
  components: {
    TestDeviceCard,
  },
  props: {
    testDevices: {
      type: Object as PropType<TestDevice[]>,
      required: true,
    },
  },
  emits: ["removeDevice"],
});
</script>
