import { RouteRecordRaw } from "vue-router";

import { IN_APP_TYPES } from "@/lib/inapps/inAppType";

import { mapParamsRouteGuard } from "../mapParamsRouteGuard";
import { toNumberOrNull } from "./queryParsing";

const isSupportedInAppId = (id: number) =>
  IN_APP_TYPES.some((t) => t.id === id);

export const routesInApps: RouteRecordRaw[] = [
  {
    name: "InApp",
    path: "/inapps/message/:product(\\d+)/:productLine(\\d+)/:inAppId(\\d+)/",
    component: () => import("@/views/inapps/InApp.vue"),
    props: (route) => {
      return {
        productId: Number(route.params.product),
        productLineId: Number(route.params.productLine),
        inAppId: Number(route.params.inAppId),
        inAppTypeId: null, // It is edit so no inAppTypeId.
      };
    },
  },
  {
    name: "InAppShortcut",
    path: "/inapps/message/:inAppId(\\d+)?/",
    component: () => import("@/views/inapps/InAppShortcut.vue"),
    props: (route) => {
      return {
        inAppId: route.params.inAppId,
      };
    },
  },
  {
    name: "InAppsList",
    path: "/inapps/message-list/:product(\\w+)/:productLine(\\w+)/",
    component: () => import("@/views/inapps/InApps.vue"),
    beforeEnter: mapParamsRouteGuard,
    props: (route) => {
      return {
        queryParams: route.query,
        product: Number(route.params.productProp),
        productLine: Number(route.params.productLineProp),
      };
    },
  },
  {
    name: `InAppCreate`,
    path: `/inapps/create-message/:product(\\d+)/:productLine(\\d+)/:inAppTypeId(\\d+)`,
    component: () => import("@/views/inapps/InApp.vue"),
    props: (route) => {
      return {
        productId: Number(route.params.product),
        productLineId: Number(route.params.productLine),
        inAppId: null, // It is "create" so no inAppId.
        inAppTypeId: Number(route.params.inAppTypeId),
        cloneFromInAppId: toNumberOrNull(route.query, "cloneFromInAppId"),
        cloneFromProductLineId: toNumberOrNull(
          route.query,
          "cloneFromProductLineId"
        ),
      };
    },
    beforeEnter: (to, _, next) =>
      isSupportedInAppId(Number(to.params.inAppTypeId))
        ? next()
        : next({ name: "NotFound" }),
  },
];
