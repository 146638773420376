import { RouteRecordRaw } from "vue-router";

import { domUtils } from "@/lib/domUtils";

import Home from "../views/home/Home.vue";
import NotFound from "../views/NotFound.vue";
import { routesInApps } from "./routes/routesInApps";
import { routesPushMessages } from "./routes/routesPushMessages";
import { routesTargetings } from "./routes/routesTargetings";
import { routesTestDevices } from "./routes/routesTestDevices";

export const routes: RouteRecordRaw[] = [
  {
    name: "Login",
    path: "/login",
    redirect: () => {
      if (process.env.VUE_APP_PROXY_DISABLED !== undefined) {
        // TODO(PNS-1597): Drop when implementing login page on new FE.
        throw new Error(
          "Tried to redirect to login on VUE_APP_PROXY_DISABLED."
        );
      }
      const windowObj = domUtils.getWindow();
      const encodedUrl = encodeURIComponent(
        `${windowObj.location.pathname}${windowObj.location.search}`
      );
      windowObj.location.href = `/login/?next=${encodedUrl}`;
      return "";
    },
  },
  { path: "/", name: "Home", component: Home },
  { path: "/not-found", name: "NotFound", component: NotFound },
  { path: "/:pathMatch(.*)*", name: "NotFound", component: NotFound },
  ...routesPushMessages,
  ...routesInApps,
  ...routesTargetings,
  ...routesTestDevices,
];
