<template>
  <q-layout view="hHh LpR fFf">
    <q-header elevated class="bg-primary text-white">
      <q-toolbar>
        <q-btn
          dense
          flat
          round
          icon="menu"
          data-test="header.button.togglemenu"
          @click="toggleLeftDrawer"
        />
        <q-toolbar-title> PNS Console </q-toolbar-title>

        <!-- #toolbar allows child pages to inject extra toolbar elements using Teleport component -->
        <div id="toolbar"></div>

        <span v-if="isUserError">Failed to fetch current user</span>
        <span v-else-if="!isUserLoading" data-test="header.label.loggeduser">
          <q-btn stretch flat :label="`Logged in as ${currentUserName}`">
            <q-menu fit>
              <q-list>
                <q-item v-close-popup clickable @click="redirectToLogout">
                  <q-item-section avatar>
                    <q-icon name="logout" />
                  </q-item-section>
                  <q-item-section>
                    <q-item-label>Log out</q-item-label>
                  </q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>
        </span>
      </q-toolbar>
    </q-header>

    <q-drawer v-model="leftDrawerOpen" show-if-above side="left" bordered>
      <nav-menu
        :menu="menu ?? undefined"
        :is-loading="isMenuLoading"
        :is-error="isMenuError"
      />
    </q-drawer>

    <q-page-container class="page-container">
      <router-view v-if="config !== null" />
      <template v-else>
        <q-spinner data-test="app.loading" size="xl" color="primary" />
      </template>
    </q-page-container>
  </q-layout>
</template>
<script lang="ts" setup>
import { toRefs } from "vue";
import { Router } from "vue-router";

import NavMenu from "./components/navmenu/NavMenu.vue";
import { redirectToLogout } from "./lib/logout";
import { Sentry } from "./lib/sentry";
import { useApp } from "./useApp";

const props = defineProps<{
  sentry: Sentry;
  router: Router;
}>();

const {
  leftDrawerOpen,
  currentUserName,
  isUserError,
  isUserLoading,
  isMenuLoading,
  isMenuError,
  menu,
  toggleLeftDrawer,
  fetchOrRouteToLogin,
  config,
} = useApp(toRefs(props));

fetchOrRouteToLogin();
</script>

<style lang="scss" scoped>
.page-container {
  height: 100vh;
}
</style>
