<template>
  <q-spinner-cube v-if="isLoading" size="xl" color="primary" class="spinner" />

  <error-indicator
    v-else-if="isError"
    data-test="navmenu.error"
    message="An error has occurred during loading menu"
  />

  <q-list v-else class="text-primar container column justify-between no-wrap">
    <div class="menu__top col-grow">
      <nav-menu-expandable
        caption="Product"
        data-test="navmenu.product"
        :items="products"
        :selected-item="productActive"
        :always-expanded="keepExpanded"
        @choose="productSelect"
      />
      <nav-menu-expandable
        data-test="navmenu.productline"
        caption="Product Line"
        :items="productLines"
        :selected-item="productLineActive"
        :always-expanded="keepExpanded"
        @choose="productLineSelect"
      />
      <nav-menu-expandable
        :class="`${featureSectionBackgroundClass} menu__feature`"
        data-test="navmenu.feature"
        caption="Feature"
        :items="features"
        :selected-item="featureActive"
        :always-expanded="keepExpanded"
        @choose="featureSelect"
      />
    </div>

    <div class="toggles">
      <q-toggle v-model="keepExpanded" label="keep expanded" left-label />
    </div>

    <div class="menu__bottom col-shrink">
      <q-separator />
      <q-item
        v-for="page in pages"
        :key="page.name"
        v-ripple
        clickable
        :to="page.route"
      >
        <q-item-section avatar>
          <q-icon :name="`img:${page.icon}`" height="25px" fit="scale-down" />
        </q-item-section>
        <q-item-section class="label" data-test="navmenu.item">
          {{ page.name }}
        </q-item-section>
      </q-item>

      <q-item v-ripple clickable @click="redirectToLogout">
        <q-item-section avatar>
          <q-icon name="logout" />
        </q-item-section>
        <q-item-section class="label" data-test="navmenu.item.logout">
          Log out
        </q-item-section>
      </q-item>
    </div>
  </q-list>
</template>

<script lang="ts" setup>
import { computed, ref, toRefs, watch } from "vue";

import ErrorIndicator from "@/components/shared/ErrorIndicator.vue";
import { Menu } from "@/composables/typedefs";

import NavMenuExpandable from "./NavMenuExpandable.vue";
import { useNavMenu } from "./useNavMenu";

const props = withDefaults(
  defineProps<{
    menu: Menu;
    isLoading: boolean;
    isError: boolean;
  }>(),
  { menu: () => ({ products: [], pages: [] }) }
);

const keepExpandedKey = "menu.keepExpanded";

const loadKeepExpanded = () => {
  const parsed = JSON.parse(
    window.localStorage.getItem(keepExpandedKey) ?? "false"
  );
  if (typeof parsed === "boolean") {
    return parsed;
  }
  return false;
};

const persistKeepExpanded = (v: boolean) => {
  window.localStorage.setItem(keepExpandedKey, JSON.stringify(v));
};

const keepExpanded = ref<boolean>(loadKeepExpanded());
watch(keepExpanded, (v) => {
  persistKeepExpanded(v);
});

const {
  redirectToLogout,
  products,
  productActive,
  productSelect,

  productLines,
  productLineActive,
  productLineSelect,

  features,
  featureActive,
  featureSelect,

  pages,
} = useNavMenu({
  ...toRefs(props),
  locationUrl: window.location.href,
});

const featureSectionBackgroundClass = computed(() => {
  if (!featureActive.value) {
    return "bg-light-blue-1";
  }
  return "bg-grey-1";
});
</script>

<style lang="scss" scoped>
.nav-menu-expandable + .nav-menu-expandable {
  margin-top: 1rem;
}

img {
  height: 1.5rem;
}

a {
  text-decoration: inherit;
  color: inherit;
  cursor: auto;
}

.spinner {
  position: absolute;
  margin: auto;
  margin-left: 40%;
  margin-top: 50px;
}
.error {
  margin: auto;
  margin-top: 50px;
  text-align: center;
}

.container {
  height: 100%;
}

.menu__top {
  overflow: auto;
}

.menu__feature {
  transition: all 0.6s;
}

.toggles .q-toggle {
  clear: right;
  float: right;
}
</style>
